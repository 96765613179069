import { isUserAlphaOrBeta, isUserGamma } from "@components/user-context/helper";
import type { UserContextType } from "@components/user-context/user-context";

// Only show the feature flag selector if the user is an alpha or beta user
export function getFeatureList(userContext: UserContextType | undefined) {
  if (!userContext) {
    return [];
  }

  if (isUserAlphaOrBeta(userContext) || isUserGamma(userContext)) {
    const features = userContext?.features || [];

    return features.filter((f) => f.isToggleable);
  }

  return [];
}
