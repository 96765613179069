import Link from "@components/styled-link/styled-link";
import { Stack, useTheme } from "@infinitaslearning/pixel-design-system";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { useBrand } from "@utils/use-brand";
import { useRouter } from "next/router";
import React, { memo, type PropsWithChildren } from "react";

const LogoContainer = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const theme = useTheme();
  const { opCo } = useBrand();
  const isLiber = opCo === "liber";

  return (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <Stack
        id="logo-container"
        sx={{ ...(isLiber && { backgroundColor: theme.pixel.color.palette.neutrals[100] }) }}
      >
        <Link
          href={{
            pathname: isLiber ? "https://online.liber.se" : "/",
            query: isLiber ? {} : { classroomId: router.query.classroomId },
          }}
          underline="none"
          onClick={() => {
            const elementId = "logo";
            const type = "link";
            const value = "/";
            getAnalyticsTracker()?.elementClicked({
              elementId,
              type,
              module: "primary-navigation",
              itemId: elementId,
              value,
            });
          }}
        >
          {children}
        </Link>
      </Stack>
    </Stack>
  );
};

export default memo(LogoContainer);
