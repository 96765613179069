import { UserContext } from "@components/user-context/user-context";
import { isUserInRole } from "@utils/role-helper";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useContext } from "react";

export function useGammaWave() {
  const userContext = useContext(UserContext);
  const isLoading = userContext?.me.isLoading;
  const email = userContext?.me.data?.email;
  const isUserInGammaRole = isUserInRole("gamma-wave", userContext?.me.data?.roles);
  const isGammaWaveEnabled = useFeatureFlag("gammaWaveEnabled", false);

  return {
    email,
    isLoading,
    isUserInGammaRole,
    isGammaWaveEnabled,
  };
}
