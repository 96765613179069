// source: https://github.com/mui/material-ui/blob/64e5dc1656e2c70e1b692618a5121ff67c645e78/examples/nextjs-with-typescript/src/Link.tsx
import {
  Link as PixelLink,
  type LinkProps as PixelLinkProps,
} from "@infinitaslearning/pixel-design-system";
import clsx from "clsx";
import NextLink, { type LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/router";
import type * as React from "react";

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href">,
    Omit<NextLinkProps, "href" | "as" | "onClick" | "onMouseEnter" | "onTouchStart"> {
  to: NextLinkProps["href"];
  linkAs?: NextLinkProps["as"];
}

const NextLinkComposed = function NextLinkComposed(props: NextLinkComposedProps) {
  const { to, linkAs, replace, scroll, shallow, prefetch, locale, ...other } = props;

  return (
    <NextLink
      legacyBehavior={true}
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
    >
      <PixelLink {...other} />
    </NextLink>
  );
};

type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps["as"];
  href: NextLinkProps["href"];
  linkAs?: NextLinkProps["as"]; // Useful when the as prop is shallow by styled().
} & Omit<NextLinkComposedProps, "to" | "linkAs" | "href"> &
  Omit<PixelLinkProps, "href">;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const StyledLink = function Link(props: LinkProps) {
  const {
    activeClassName = "active",
    as,
    className: classNameProps,
    href,
    linkAs: linkAsProp,
    locale,
    prefetch,
    replace,
    role: _role, // Link don't have roles.
    scroll,
    shallow,
    ...other
  } = props;

  const router = useRouter();
  const pathname = typeof href === "string" ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });

  const isExternal =
    typeof href === "string" && (href.indexOf("http") === 0 || href.indexOf("mailto:") === 0);

  if (isExternal) {
    return <PixelLink className={className} href={href} {...other} />;
  }

  const linkAs = linkAsProp || as;
  const nextjsProps = { to: href, linkAs, replace, scroll, shallow, prefetch, locale };

  return <NextLinkComposed className={className} {...nextjsProps} {...other} />;
};

export default StyledLink;
