import ClassroomDropdown from "@components/breadcrumb-bar/classroom-dropdown";
import { EvaluationLicenceContext } from "@components/evaluation-licence-context/evaluation-licence-context";
import BrandLogo from "@components/logos/brand-logo";
import BrandLogoWithTitle from "@components/logos/brand-logo-with-title";
import NavSidebar from "@components/nav-sidebar/nav-sidebar";
import LogoContainer from "@components/primary-navigation/components/logo-container";
import usePrimaryLinks from "@components/primary-navigation/components/use-primary-links";
import { getQueryValue, isLinkSelected } from "@components/primary-navigation/helpers";
import { type SideBarType, SideBarTypes } from "@components/primary-navigation/types";
import Link from "@components/styled-link/styled-link";
import { UserContext } from "@components/user-context/user-context";
import UserSidebar from "@components/user-sidebar/user-sidebar";
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@infinitaslearning/pixel-design-system";
import BarsIcon from "@infinitaslearning/pixel-icons/Bars";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { usePublicationEnv } from "@utils/persisted-values/use-publication-env";
import { useBrand } from "@utils/use-brand";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useIsUserWithoutClassroom } from "@utils/use-is-user-without-classroom";
import clsx from "clsx";
import { signIn, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { type FC, useContext, useMemo, useState } from "react";
import {
  EnvWarning,
  NavBox,
  NavItem,
  NavList,
  NavigationStyling,
  StyledNavLink,
} from "./primary-navigation.styles";
import HelpNavigation from "@components/help-navigation/help-navigation";
import UserIcon from "@infinitaslearning/pixel-icons/User";

type PrimaryNavigationProps = {
  showLinks: boolean;
  hideClassSelector?: boolean;
};

const PrimaryNavigationBar: FC<PrimaryNavigationProps> = ({ showLinks, hideClassSelector }) => {
  const user = {
    avatar: {
      url: undefined,
    },
  };
  const showLessonFinder = useFeatureFlag("useLessonFinder", false);
  const showHelpNav = useFeatureFlag("showHelpNav", false);

  const { t: tSidebar } = useTranslation("common", { keyPrefix: "user-sidebar" });

  const { hasEvaluationLicence } = useContext(EvaluationLicenceContext);

  const router = useRouter();
  const session = useSession();

  const isTempClassroomId = useIsUserWithoutClassroom();

  const [isSidebarOpen, setIsSidebarOpen] = useState<SideBarType | undefined>();
  const userContext = useContext(UserContext);

  const [userEnv] = usePublicationEnv();
  const isStaging = userEnv === "staging";

  const profileImage = user?.avatar.url;
  const theme = useTheme();

  const { opCo } = useBrand();
  const isLiber = opCo === "liber";

  const links = usePrimaryLinks(
    !!hasEvaluationLicence,
    isLiber,
    userContext?.me?.refOrganizationId,
    isTempClassroomId,
  );
  const isAuthenticated = session.status === "authenticated";

  const userLinks = useMemo(() => {
    if (!showLinks || !isAuthenticated) return [];
    return links.filter((l) => (l.role && userContext?.roles ? userContext?.roles[l.role] : true));
  }, [isAuthenticated, links, showLinks, userContext?.roles]);

  const isCompactMode = useMediaQuery(theme.breakpoints.down("md")) && !!hasEvaluationLicence;

  const handleCloseSideBar = () => setIsSidebarOpen(undefined);

  const renderCenter = () =>
    isCompactMode ? (
      <LogoContainer>
        <BrandLogoWithTitle />
      </LogoContainer>
    ) : (
      <NavList data-usetiful="primary-navigation">
        {userLinks.map((link) => (
          <NavItem key={link.link} data-usetiful={link.usetifulTag}>
            <Link
              href={{
                pathname: link.link,
                query: getQueryValue(link, router, showLessonFinder),
              }}
              onClick={() => {
                const elementId = "primary-navigation-nav-item";
                const itemId = `${link.title}`;
                const type = "link";
                const value = link.link;
                getAnalyticsTracker()?.elementClicked({
                  elementId,
                  type,
                  module: "primary-navigation",
                  itemId,
                  value,
                });
              }}
            >
              <StyledNavLink className={clsx({ selected: isLinkSelected(link, router) })}>
                {link.title}
              </StyledNavLink>
            </Link>
          </NavItem>
        ))}
      </NavList>
    );

  const renderLeft = () =>
    isCompactMode ? (
      <IconButton
        onClick={() => {
          const elementId = "menu-sidebar";
          const type = "button";
          getAnalyticsTracker()?.elementClicked({
            elementId,
            type,
            module: "primary-navigation",
            itemId: elementId,
          });
          setIsSidebarOpen(SideBarTypes.Menu);
        }}
        size="large"
        data-usetiful="menu"
        data-cy="menu"
        aria-label="Menu"
        aria-expanded={isSidebarOpen === SideBarTypes.Menu ? "true" : "false"}
      >
        <BarsIcon htmlColor={theme.pixel.cta.contained.color.text.primary.default} />
      </IconButton>
    ) : undefined;

  const renderLogo = () =>
    isCompactMode ? undefined : (
      <LogoContainer>
        <BrandLogo />
      </LogoContainer>
    );

  const renderRight = () => (
    <NavigationStyling>
      {isStaging && <EnvWarning>{tSidebar("staging_endpoint_label")}</EnvWarning>}
      {isAuthenticated ? (
        <>
          {!isCompactMode && !hideClassSelector && <ClassroomDropdown />}
          {showHelpNav && <HelpNavigation />}
          <IconButton
            onClick={() => {
              const elementId = "user-sidebar";
              const type = "button";
              getAnalyticsTracker()?.elementClicked({
                elementId,
                type,
                module: "primary-navigation",
                itemId: elementId,
              });
              setIsSidebarOpen(SideBarTypes.User);
            }}
            sx={{ mr: 2 }}
            data-usetiful="profile"
            data-cy="profile"
            aria-label="Profile menu"
            aria-expanded={isSidebarOpen === SideBarTypes.User ? "true" : "false"}
          >
            <Avatar
              sx={{
                width: 40,
                height: 40,
                cursor: "pointer",
                backgroundColor: theme.pixel.color.palette.primary[95],
              }}
              src={profileImage}
            >
              <UserIcon color="primary" />
            </Avatar>
          </IconButton>
        </>
      ) : (
        <Button
          onClick={() => signIn("identity-server4")}
          variant="outlined"
          size="small"
          color="inherit"
        >
          <Typography variant="body1Light">{tSidebar("login")}</Typography>
        </Button>
      )}
    </NavigationStyling>
  );

  return (
    <NavBox>
      <NavSidebar
        links={userLinks}
        onClose={handleCloseSideBar}
        isOpen={isSidebarOpen === SideBarTypes.Menu}
      />
      <UserSidebar closeSidebar={handleCloseSideBar} isOpen={isSidebarOpen === SideBarTypes.User} />
      <AppBar
        sx={{ pl: 0 }}
        left={renderLeft()}
        logo={renderLogo()}
        center={renderCenter()}
        right={renderRight()}
      />
    </NavBox>
  );
};
export default PrimaryNavigationBar;
