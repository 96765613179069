import { type FC, memo } from "react";

type AuthLockProps = {
  svgLabel: string;
  color: string;
};

const AuthLock: FC<AuthLockProps> = ({ svgLabel, color }) => (
  <svg width={146} height={234}>
    <title>{svgLabel}</title>
    <g fill={color}>
      <path d="M124 85.333H21.6C9.832 85.333.267 94.908.267 106.666v76.8c0 11.76 9.565 21.334 21.333 21.334H124c11.768 0 21.333-9.574 21.333-21.333v-76.8c0-11.76-9.565-21.334-21.333-21.334zm12.8 98.134c0 7.057-5.743 12.8-12.8 12.8H21.6c-7.057 0-12.8-5.743-12.8-12.8v-76.8c0-7.057 5.743-12.8 12.8-12.8H124c7.057 0 12.8 5.743 12.8 12.8v76.8z" />
      <path d="M72.8 0C42.216 0 17.333 24.883 17.333 55.467V89.6a4.268 4.268 0 004.267 4.267H124a4.268 4.268 0 004.267-4.267V55.467C128.267 24.883 103.384 0 72.8 0zm46.933 85.333H25.867V55.466c0-25.881 21.06-46.933 46.933-46.933s46.933 21.052 46.933 46.933v29.867zM72.8 145.067a4.268 4.268 0 00-4.267 4.267v25.6A4.268 4.268 0 0072.8 179.2a4.268 4.268 0 004.267-4.266v-25.6a4.268 4.268 0 00-4.267-4.267z" />
      <path d="M72.8 110.933c-11.768 0-21.333 9.575-21.333 21.333 0 11.76 9.565 21.334 21.333 21.334 11.768 0 21.333-9.574 21.333-21.333 0-11.759-9.565-21.334-21.333-21.334zm0 34.134c-7.057 0-12.8-5.743-12.8-12.8s5.743-12.8 12.8-12.8 12.8 5.743 12.8 12.8-5.743 12.8-12.8 12.8z" />
      <ellipse fillOpacity="0.12" cx={73} cy={227.5} rx={73} ry={6.5} />
    </g>
  </svg>
);

const MemoAuthLock = memo(AuthLock);
export default MemoAuthLock;
