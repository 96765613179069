import { Box, styled } from "@infinitaslearning/pixel-design-system";

export const NAVBAR_HEIGHT = 80;

export const NavBox = styled(Box)`
  padding-bottom: 80px;
  height: ${NAVBAR_HEIGHT}px;
`;

export const NavigationStyling = styled("nav")`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: ${NAVBAR_HEIGHT}px;
`;

export const NavList = styled("ul")`
  list-style: none;
  display: flex;
  margin: 0 auto 0 0;
`;

export const NavItem = styled("li")`
  margin-right: 15px;
`;

export const StyledNavLink = styled("span")`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.white};
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  &.selected {
    border: 1px solid ${(props) => props.theme.pixel.color.palette.secondary[50]};
    background: ${(props) => props.theme.pixel.color.palette.primary[20]};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export const EnvWarning = styled("div")`
  background-color: ${({ theme }) => theme.pixel.color.system.lemon[80]};
  color: ${({ theme }) => theme.palette.common.black};
  padding: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  border-radius: 3px;
`;
