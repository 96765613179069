import type React from "react";
import {
  Box,
  Link,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@infinitaslearning/pixel-design-system";
import type { HelpNavMenuItem } from "@components/help-navigation/use-help-navigation";

type HelpNavigationMenuItemProps = {
  item: HelpNavMenuItem;
  handleClose: () => void;
  isInclass?: boolean;
};

const HelpNavigationMenuItem: React.FC<HelpNavigationMenuItemProps> = ({
  item,
  handleClose,
  isInclass,
}) => {
  const { name, href, handleClick, icon, isHiddenInclass, isOpenNewTab } = item;
  const theme = useTheme();
  return (
    <Box
      key={`menu-item-${name}`}
      sx={{
        display: isInclass && isHiddenInclass ? "none" : "flex",
        width: "100%",
        cursor: "pointer",
        ":hover": {
          backgroundColor: theme.pixel.cta.outlined.color.background.neutral.hover,
        },
      }}
      onClick={() => {
        handleClick?.();
        handleClose();
      }}
    >
      <Link
        sx={{ width: "100%" }}
        underline="none"
        href={href ? href : undefined}
        target={isOpenNewTab ? "_blank" : undefined}
      >
        <Box sx={{ display: "flex", py: 1.5, px: 2, gap: 2, width: "100%" }}>
          <ListItemIcon sx={{ fontSize: "20px" }}>{icon}</ListItemIcon>
          <ListItemText>{name}</ListItemText>
        </Box>
      </Link>
    </Box>
  );
};

export default HelpNavigationMenuItem;
