const UPPER_BORDER_RADIUS = "4px !important";

const SHARED_BUTTON_SX = {
  borderRadius: "0px !important",
  // left align content
  justifyContent: "flex-start !important",
};

export const getToggleGroupButtonSX = (
  borderRadiusDirection: "left" | "right",
  shouldAddBottomRadius?: boolean,
) => {
  if (borderRadiusDirection === "left") {
    return {
      ...SHARED_BUTTON_SX,
      borderTopLeftRadius: UPPER_BORDER_RADIUS,
      ...(shouldAddBottomRadius ? { borderBottomLeftRadius: UPPER_BORDER_RADIUS } : {}),
    };
  }
  return {
    ...SHARED_BUTTON_SX,
    borderTopRightRadius: UPPER_BORDER_RADIUS,
    ...(shouldAddBottomRadius ? { borderBottomRightRadius: UPPER_BORDER_RADIUS } : {}),
  };
};
