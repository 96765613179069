import type { UserContextType } from "@components/user-context/user-context";

export const SideBarTypes = {
  User: "user",
  Menu: "menu",
} as const;

export type SideBarType = (typeof SideBarTypes)[keyof typeof SideBarTypes];

export type LinkType = {
  mainRoute?: string;
  link: string;
  title: string;
  usetifulTag: string;
  role?: keyof UserContextType["roles"];
};
