import AuthLock from "@components/svg/auth-lock/auth-lock";
import { type Theme, useTheme, Box } from "@infinitaslearning/pixel-design-system";
import Image from "next/legacy/image";
import { useTranslation } from "next-i18next";
import type { FC } from "react";

import cowImage from "/public/images/animal-images/cow1.png";
import dogFrisbee from "/public/images/animal-images/dog_frisbee.png";
import dogStopImage from "/public/images/animal-images/dog_stop.png";
import duckImage from "/public/images/animal-images/duck.png";
import flamingoImage from "/public/images/animal-images/flamingo.png";
import frogImage from "/public/images/animal-images/frog.png";
import heronImage from "/public/images/animal-images/heron.png";
import type {
  PlatformErrorDisplayTypes,
  PlatformErrorTypes,
} from "@components/platform-error/platform-error-types";

import AuthErrorIcon from "@infinitaslearning/pixel-icons/UserShield";
import DataErrorIcon from "@infinitaslearning/pixel-icons/TableCells";
import RoleErrorIcon from "@infinitaslearning/pixel-icons/EyeSlash";
import LockedErrorIcon from "@infinitaslearning/pixel-icons/Hand";
import UnauthErrorIcon from "@infinitaslearning/pixel-icons/Lock";
import OtherErrorIcon from "@infinitaslearning/pixel-icons/Ban";
import PlayerErrorIcon from "@infinitaslearning/pixel-icons/Gear";
import NavigationErrorIcon from "@infinitaslearning/pixel-icons/Map";
import type { StaticImageData } from "next/image";

const IMAGE_CONTAINER_PADDING = 5;
const IMAGE_CONTAINER_SIZE = 300;
const IMAGE_CONTAINER_MAX_SIZE = "75%";

const ICON_FONT_SIZE = "xLarge3";
const ICON_COLOR = "primary";

const getFullScreenSX = (shouldCenterImage: boolean) => {
  return {
    padding: IMAGE_CONTAINER_PADDING,
    height: IMAGE_CONTAINER_SIZE,
    width: IMAGE_CONTAINER_SIZE,
    maxHeight: IMAGE_CONTAINER_MAX_SIZE,
    maxWidth: IMAGE_CONTAINER_MAX_SIZE,
    display: shouldCenterImage ? "flex" : "initial",
    alignItems: shouldCenterImage ? "center" : "initial",
    justifyContent: shouldCenterImage ? "center" : "initial",
  };
};

const getNotifcationIconSX = () => {
  return {
    alignItems: "center",
    justifyContent: "center",
  };
};

const getResponsiveIconSX = () => {
  return {
    padding: 0.5,
    margin: 0.25,
  };
};

const primary30 = (theme: Theme) =>
  theme.pixel?.color.palette.primary[30] ?? theme.palette.primary.dark;

type PlatformErrorImageProps = {
  errorType: PlatformErrorTypes;
  displayType: PlatformErrorDisplayTypes;
};

const getErrorDisplay = (
  shouldDisplayAsImage: boolean,
  // biome-ignore lint/suspicious/noExplicitAny: Props are unknown here
  Icon: React.ComponentType<any>,
  imageSource: StaticImageData,
  imagAlt: string,
) => {
  return shouldDisplayAsImage ? (
    <Image {...imageProps} src={imageSource} alt={imagAlt} />
  ) : (
    <Icon fontSize={ICON_FONT_SIZE} color={ICON_COLOR} />
  );
};

const getUnauthDisplay = (
  shouldDisplayAsImage: boolean,
  // biome-ignore lint/suspicious/noExplicitAny: Props are unknown here
  Icon: React.ComponentType<any>,
  color: string,
  svgLabel: string,
) => {
  return shouldDisplayAsImage ? (
    <AuthLock color={color} svgLabel={svgLabel} />
  ) : (
    <Icon fontSize={ICON_FONT_SIZE} color={ICON_COLOR} />
  );
};

// pretty sad, but https://github.com/vercel/next.js/discussions/18474#discussioncomment-5501724
const imageProps: {
  width: number;
  height: number;
  objectFit: "contain";
  // biome-ignore lint/suspicious/noExplicitAny: Type is unknown
  style: Record<string, any>;
} = {
  width: 0,
  height: 0,
  objectFit: "contain",
  style: { width: "auto", height: "auto" },
};

const ErrorDisplay: FC<PlatformErrorImageProps> = ({ errorType, displayType }) => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const { t: tUnAuth } = useTranslation("unauth");
  const willBeImg = displayType === "full";
  switch (errorType) {
    case "unauthenticated":
      return getUnauthDisplay(willBeImg, UnauthErrorIcon, primary30(theme), tUnAuth("unauth.svg"));
    case "unauthorized":
      return getErrorDisplay(willBeImg, AuthErrorIcon, flamingoImage, t("animal-images.flamingo"));
    case "data":
      return getErrorDisplay(willBeImg, DataErrorIcon, heronImage, t("animal-images.heron"));
    case "role":
      return getErrorDisplay(willBeImg, RoleErrorIcon, frogImage, t("animal-images.frog"));
    case "locked_lu":
      return getErrorDisplay(willBeImg, LockedErrorIcon, dogStopImage, t("animal-images.dogStop"));
    case "navigation":
      return getErrorDisplay(willBeImg, NavigationErrorIcon, cowImage, t("animal-images.cow"));
    case "player_content":
      return getErrorDisplay(willBeImg, PlayerErrorIcon, dogFrisbee, t("animal-images.dogFrisbee"));
    default:
      return getErrorDisplay(willBeImg, OtherErrorIcon, duckImage, t("animal-images.duck"));
  }
};

const PlatformErrorImage: FC<PlatformErrorImageProps> = ({ errorType, displayType }) => {
  const shouldCenterImage = errorType !== "other";

  return (
    <Box
      sx={
        displayType === "full"
          ? getFullScreenSX(shouldCenterImage)
          : displayType === "notification"
            ? getNotifcationIconSX()
            : getResponsiveIconSX()
      }
    >
      <ErrorDisplay errorType={errorType} displayType={displayType} />
    </Box>
  );
};

export default PlatformErrorImage;
