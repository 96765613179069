import { useBrand } from "@utils/use-brand";

export const useGetOpcoSpecificHomeButton = () => {
  const { opCo } = useBrand();

  if (opCo === "liber") {
    return "https://online.liber.se";
  }
  return "/";
};
