import { FullScreenError } from "@components/platform-error/fullscreen-error";
import { NotificationError } from "@components/platform-error/notification-error";
import { HomeCTA, ReloadCTA } from "@components/platform-error/platform-error-ctas";
import {
  getErrorMessage,
  getErrorTitle,
  useLogError,
} from "@components/platform-error/platform-error-utils";
import { ResponsiveError } from "@components/platform-error/responsive-error";
import { useTranslation } from "react-i18next";

import type { ResponsiveErrorProps } from "@components/platform-error/responsive-error";
import type {
  DataErrorTypes,
  ErrorComponentProps,
  PlatformErrorCodes,
  PlatformErrorDisplayTypes,
  PlatformErrorTypes,
} from "@components/platform-error/platform-error-types";
import type { SxProps, Theme } from "@infinitaslearning/pixel-design-system";

export type PlatformErrorProps = {
  // Recommended props
  dataErrorType?: DataErrorTypes;
  displayType?: PlatformErrorDisplayTypes;
  errorType?: PlatformErrorTypes;
  errorCode?: PlatformErrorCodes;
  error?: Error | unknown;
  // Custom props
  CustomCTA?: JSX.Element | null;
  CustomCTADefaultBehaviour?: "reload" | "home";
  customMessage?: string;
  customTitle?: string;
  // Styling props
  noBackground?: boolean;
  // Other props
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

export const PlatformError: React.FC<PlatformErrorProps> = ({
  displayType = "full",
  errorType = "other",
  noBackground = false,
  customMessage,
  customTitle,
  errorCode,
  error,
  children,
  CustomCTA,
  CustomCTADefaultBehaviour,
  dataErrorType,
  sx,
}) => {
  const { t } = useTranslation("common");

  const title = customTitle || getErrorTitle(t, error);
  const message = customMessage || getErrorMessage(t, error, dataErrorType);

  const displayNoCTA = CustomCTA === null;
  const useCustomCTA = CustomCTA && CustomCTA !== null;
  const CTA = useCustomCTA ? (
    CustomCTA
  ) : displayNoCTA ? null : CustomCTADefaultBehaviour === "home" ? (
    <HomeCTA />
  ) : (
    <ReloadCTA />
  );

  const errorCompProps: ErrorComponentProps = {
    errorType,
    title,
    t,
    errorCode,
    customMessage,
    message,
    children,
    CTA,
    sx,
  };

  const responseErrorProps: ResponsiveErrorProps = {
    ...errorCompProps,
    useFull: displayType === "responsive_full",
    noBackground: noBackground,
  };

  useLogError(title, message, errorType, error);

  switch (displayType) {
    case "notification":
      return <NotificationError {...errorCompProps}>{children}</NotificationError>;
    case "responsive":
    case "responsive_full":
      return <ResponsiveError {...responseErrorProps}>{children}</ResponsiveError>;
    default:
      return <FullScreenError {...errorCompProps}>{children}</FullScreenError>;
  }
};
