import type { LinkType } from "@components/primary-navigation/types";
import { MAIN_ROUTES, NEWS_ROUTE } from "@utils/router-helper";
import type { NextRouter } from "next/router";

export const getQueryValue = (link: LinkType, router: NextRouter, showLessonFinder: boolean) => {
  if (link.link.startsWith(MAIN_ROUTES.content)) {
    return showLessonFinder
      ? {
          classroomId: router.query.classroomId,
          subjectName: router.query.subjectName,
          search: router.query.search,
        }
      : {
          classroomId: router.query.classroomId,
          methodName: router.query.methodName,
          subjectName: router.query.subjectName,
        };
  }
  return {
    classroomId: router.query.classroomId,
  };
};

export const isLinkSelected = (link: LinkType, router: NextRouter): boolean => {
  const isHomePage = link.link === MAIN_ROUTES.home;
  const currentRoute = router.route;
  // Make sure only the home page was selected
  let hasOneLinkSelected = true;
  if (isHomePage) {
    hasOneLinkSelected =
      !currentRoute.startsWith(MAIN_ROUTES.admin) &&
      !currentRoute.startsWith(MAIN_ROUTES.content) &&
      !currentRoute.startsWith(MAIN_ROUTES.evaluationOverview) &&
      !currentRoute.startsWith(MAIN_ROUTES.evaluationGrowth) &&
      !currentRoute.startsWith(MAIN_ROUTES.gammaWaveProgram) &&
      !currentRoute.startsWith(NEWS_ROUTE) &&
      !currentRoute.startsWith(MAIN_ROUTES.tasksManager);
  }
  return currentRoute.startsWith(link.mainRoute || link.link) && hasOneLinkSelected;
};
