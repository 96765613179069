import Feedback, { type Metadata } from "@infinitaslearning/module-il-feedback-widget";
import type { AnalyticsArgs } from "@infinitaslearning/module-il-feedback-widget/dist/types/AnalyticsArgs";
import { Typography } from "@infinitaslearning/pixel-design-system";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { useBrand } from "@utils/use-brand";
import { useSelectedNavigation } from "@utils/use-selected-navigation";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { type FC, type ReactNode, useCallback, useEffect, useState } from "react";

type ContentFeedbackProps = {
  customButton?: ReactNode;
};

export const ContentFeedback: FC<ContentFeedbackProps> = ({ customButton }) => {
  const [token, setToken] = useState("");
  const { t } = useTranslation("common", { keyPrefix: "feedback" });
  const { data: session } = useSession();
  const { opCo } = useBrand();
  const { selected } = useSelectedNavigation();

  const trackAnalytics = useCallback((data: AnalyticsArgs) => {
    getAnalyticsTracker()?.elementClicked(data);
  }, []);

  const collectMetadata = useCallback(async (): Promise<Metadata> => {
    const feedbackMetadata: Metadata = {
      method: selected?.selectedMethod?.name ?? "",
      bookTargetYear: selected?.selectedMethodGroup?.name ?? "",
      chapterShortTitle: selected?.selectedChapter?.name ?? "",
      paragraphShortTitle: selected?.selectedParagraph?.name ?? "",
    };

    return feedbackMetadata;
  }, [selected]);

  const tokenProvider = useCallback(
    () => new Promise<string>((resolve) => resolve(token)),
    [token],
  );

  useEffect(() => {
    if (session?.idToken) setToken(session.idToken);
  }, [session]);

  if (!process.env.NEXT_PUBLIC_FEEDBACK_ENDPOINT) return null;
  return (
    <Feedback
      feedbackServiceUrl={process.env.NEXT_PUBLIC_FEEDBACK_ENDPOINT}
      opco={opCo.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())}
      segment="Primary"
      tokenProvider={tokenProvider}
      customButton={customButton}
      buttonTextTransform="none"
      buttonSize="small"
      collectMetadata={collectMetadata}
      trackAnalytics={trackAnalytics}
      texts={{
        feedbackButtonText: t("button"),
        confirmationClose: t("confirmationClose"),
        confirmationMessage: t("confirmationMessage"),
        confirmationTitle: t("confirmationTitle"),
        sendFailureMessage: t("sendFailureMessage"),
        sendFailureTitle: t("sendFailureTitle"),
        feedbackModalTexts: {
          category: t("category"),
          describeError: t("describeError"),
          description: t("description"),
          pickCategory: t("pickCategory"),
          feedbackTypes: [
            { key: "Content", text: t("contentIssue") },
            { key: "Answer", text: t("answerIssue") },
            { key: "CantFinish", text: t("cantFinish") },
            { key: "AnswerNotStored", text: t("answerNotStored") },
            { key: "ContentNotLoading", text: t("contentNotLoading") },
            {
              key: "Other",
              text: t("otherIssue"),
              component: (
                <Typography>
                  {t("contactInformation1")}
                  <a href={t("customerServiceUrl")}> {t("customerServiceUrl")} </a>
                  {t("contactInformation2")}
                </Typography>
              ),
              hideForm: true,
            },
          ],
          optIn: t("optIn"),
          invalidEmail: t("invalidEmail"),
          privacy: {
            policy1: t("policy1"),
            policy2: t("policy2"),
            policy3: t("policy3"),
            url: t("policyUrl"),
          },
          confirmDialogTexts: {
            confirmationLabel: t("submit"),
            description: t("information"),
            title: t("header"),
            rejectLabel: t("cancel"),
          },
        },
      }}
    />
  );
};
