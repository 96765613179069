import { createWithEqualityFn as create } from "zustand/traditional";

type ToursModalState = {
  isToursModalOpen: boolean;
  setIsToursModalOpen: (newSelectedYearGroup: boolean) => void;
};

export const useToursModalOpen = create<ToursModalState>((set) => ({
  isToursModalOpen: false,
  setIsToursModalOpen: (newToursModalState) =>
    set(() => ({
      isToursModalOpen: newToursModalState,
    })),
}));
