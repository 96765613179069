import type React from "react";
import type { MouseEvent } from "react";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ToggleButton,
  useTheme,
} from "@infinitaslearning/pixel-design-system";
import { useState } from "react";
import CircleQuestionIcon from "@infinitaslearning/pixel-icons/CircleQuestion";
import { useHelpNavMenuItems } from "@components/help-navigation/use-help-navigation";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { ContentFeedback } from "@components/feedback/content-feedback";
import MessagesIcon from "@infinitaslearning/pixel-icons/Messages";
import { useTranslation } from "react-i18next";
import HelpNavigationMenuItem from "@components/help-navigation/help-navigation-menu-item";
import { ToursModal } from "@components/help-navigation/tours-modal";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { useRouter } from "next/router";
import { TallyFeedbackButton } from "@components/help-navigation/help-navigation-tally-feedback-button";

type HelpNavigationProps = {
  isInclass?: boolean;
};

const HelpNavigation: React.FC<HelpNavigationProps> = ({ isInclass }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const showContentFeedback = useFeatureFlag("showContentFeedback", false);
  const useNewTallyFeedbackForm = useFeatureFlag("useTallyFeedbackForm", false);
  const { t } = useTranslation("common", { keyPrefix: "help-navigation" });
  const router = useRouter();
  const isInContentView = router.asPath.includes("content");

  const trackElementClick = (elementId: string) => {
    getAnalyticsTracker()?.elementClicked({
      elementId,
      type: "button",
      module: "help-navigation",
      itemId: elementId,
    });
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    trackElementClick("help-navigation-toggle");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { news, help, tour } = useHelpNavMenuItems();

  return (
    <Box mx={1}>
      <ToggleButton
        id="help-nav-button"
        selected={open}
        value="help"
        onClick={handleClick}
        sx={{
          // hide border as noBorder prop is no longer working since pixel version: "29.4.4"
          border: isInclass ? "none !important" : "",
        }}
      >
        <CircleQuestionIcon
          fontSize="large"
          htmlColor={
            isInclass
              ? theme.pixel.color.primary.key
              : open
                ? theme.palette.common.black
                : theme.palette.common.white
          }
        />
      </ToggleButton>
      <Menu
        sx={{ mt: 1 }}
        id="help-menu"
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "help-nav-button",
        }}
      >
        <HelpNavigationMenuItem item={news} handleClose={handleClose} isInclass={isInclass} />
        {showContentFeedback && isInContentView && !useNewTallyFeedbackForm && (
          <ContentFeedback
            customButton={
              <MenuItem>
                <ListItemIcon>
                  <MessagesIcon />
                </ListItemIcon>
                <ListItemText>{t("feedback")}</ListItemText>
              </MenuItem>
            }
          />
        )}
        {isInContentView && showContentFeedback && useNewTallyFeedbackForm && (
          <TallyFeedbackButton />
        )}
        <HelpNavigationMenuItem item={help} handleClose={handleClose} isInclass={isInclass} />
        <HelpNavigationMenuItem item={tour} handleClose={handleClose} isInclass={isInclass} />
      </Menu>
      <ToursModal />
    </Box>
  );
};

export default HelpNavigation;
