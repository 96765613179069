import { useCallback, useEffect } from "react";

interface Props {
  key: string;
  onPress: () => void;
}

export function useKeyboardBinding({ key, onPress }: Props) {
  const handler = useCallback(
    (event: KeyboardEvent) => {
      // ignore if a modifier key is pressed as well
      if (event.ctrlKey || event.altKey || event.shiftKey) {
        return;
      }
      if (event.key === key) {
        onPress();
      }
    },
    [key, onPress],
  );

  useEffect(() => {
    document.addEventListener("keydown", handler, false);

    return () => {
      document.removeEventListener("keydown", handler, false);
    };
  }, [handler]);
}
