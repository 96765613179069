import JuniorCloudLogo from "@components/logos/jc-logo";
import LiberLogo from "@components/logos/liber-logo";
import ScoodleLogo from "@components/logos/scoodle-logo";
import { useBrand } from "@utils/use-brand";
import React, { memo } from "react";

export const LABEL_MAP = {
  noordhoff: "Junior Cloud",
  plantyn: "Scoodle",
  liber: "Liber",
};

const LOGO_MAP = {
  noordhoff: (props: LogoProps) => <JuniorCloudLogo {...props} />,
  plantyn: (props: LogoProps) => <ScoodleLogo {...props} />,
  liber: (props: LogoProps) => <LiberLogo {...props} />,
};

export type LogoProps = {
  variant?: "block" | "circle";
  label: string;
};

export type BrandLogoProps = Omit<LogoProps, "label">;

const BrandLogo = ({ variant = "block" }: BrandLogoProps) => {
  const { opCo } = useBrand();

  return LOGO_MAP[opCo]({ variant, label: LABEL_MAP[opCo] });
};

export default memo(BrandLogo);
