import {
  Box,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from "@infinitaslearning/pixel-design-system";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { useClassrooms } from "@utils/use-classrooms";
import { useRouter } from "next/router";
import type { FC } from "react";
import { StyledDropDownContainer } from "../classroom-selector/classroom-selector.styles";

type ClassroomDropdownProps = {
  lineHeight?: number;
  handleChange?: () => void;
};

const ClassroomDropdown: FC<ClassroomDropdownProps> = ({ lineHeight = 3.5, handleChange }) => {
  const { data: classroomsData, isLoading: isLoadingClassrooms } = useClassrooms({
    staleTime: Number.POSITIVE_INFINITY,
  });

  const router = useRouter();

  const selectedClassroom = router.query.classroomId as string;

  const trackElementClicked = (
    elementId: string,
    type = "button",
    itemId?: string,
    value?: string,
  ) => {
    getAnalyticsTracker()?.elementClicked({
      elementId,
      type,
      module: "classroom-dropdown",
      itemId,
      value,
    });
  };

  const handleSelectClassroom = (event: SelectChangeEvent<string>) => {
    trackElementClicked("classroom-selection", "select", "classroom-select");
    handleChange?.();
    router.replace(
      {
        query: { ...router.query, classroomId: event.target.value },
      },
      undefined,
      { shallow: true },
    );
  };

  if (classroomsData === undefined || isLoadingClassrooms) return null;

  const MultipleClassrooms = () => {
    return (
      <StyledDropDownContainer>
        <Select value={selectedClassroom} onChange={handleSelectClassroom}>
          {classroomsData?.classroomsOfUser
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((classroom) => (
              <MenuItem value={classroom.id} key={classroom.id} density="dense">
                {classroom.name}
              </MenuItem>
            ))}
        </Select>
      </StyledDropDownContainer>
    );
  };

  const SingleClassroom = () => {
    return (
      <StyledDropDownContainer>
        <Box sx={{ lineHeight }}>{classroomsData.classroomsOfUser[0].name}</Box>
      </StyledDropDownContainer>
    );
  };

  if (classroomsData.classroomsOfUser.length > 1 && router.query.classroomId) {
    return <MultipleClassrooms />;
  }
  if (classroomsData.classroomsOfUser.length === 1) {
    return <SingleClassroom />;
  }

  return null;
};

export default ClassroomDropdown;
