import { Button, Typography } from "@infinitaslearning/pixel-design-system";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useGetOpcoSpecificHomeButton } from "@components/platform-error/utils/useGetOpcoSpecificHomeButton";

const BTN_SIZE = "large";
const BTN_COLOR = "primary";
const BTN_VAR = "contained";
const LABEL_VAR = "buttonLarge";
const LABEL_COLOR = "common.white";

export const ReloadCTA = () => {
  const router = useRouter();
  const { t } = useTranslation("common");
  return (
    <Button size={BTN_SIZE} color={BTN_COLOR} variant={BTN_VAR} onClick={router.reload}>
      <Typography variant={LABEL_VAR} color={LABEL_COLOR}>
        {t("platform-error.reload-cta")}
      </Typography>
    </Button>
  );
};

export const HomeCTA = () => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const homePageRoute = useGetOpcoSpecificHomeButton();

  return (
    <Button
      size={BTN_SIZE}
      color={BTN_COLOR}
      variant={BTN_VAR}
      onClick={() => router.push(homePageRoute)}
    >
      <Typography variant={LABEL_VAR} color={LABEL_COLOR}>
        {t("platform-error.home-cta")}
      </Typography>
    </Button>
  );
};
