import {
  Button,
  Paper,
  Stack,
  Typography,
  type Theme,
} from "@infinitaslearning/pixel-design-system";
import { useTranslation } from "react-i18next";
import { ArrowRightAlt } from "@infinitaslearning/pixel-icons/mui";
import { useGammaWave } from "./helpers/useGammaWave.hook";

const colorLight = (theme: Theme) =>
  theme.pixel.color.palette.primary[90] ?? theme.palette.primary.light;
const colorDark = (theme: Theme) =>
  theme.pixel.color.palette.primary[10] ?? theme.palette.primary.dark;

export const InsiderProgramCTA = () => {
  const { t } = useTranslation("common", { keyPrefix: "user-sidebar" });
  const { isGammaWaveEnabled, isUserInGammaRole } = useGammaWave();

  if (isUserInGammaRole || !isGammaWaveEnabled) {
    return null;
  }

  return (
    <Paper
      sx={(theme) => ({
        my: 2,
        backgroundColor: colorLight(theme),
        color: colorDark(theme),
      })}
      elevation={3}
    >
      <Stack p={2} gap={1}>
        <Typography variant="subtitle1Bold">{t("gamma-cta-title")}</Typography>
        <Typography>{t("gamma-cta-desc")}</Typography>
        <Button href="/insider" size="small" endIcon={<ArrowRightAlt />}>
          <Typography>{t("gamma-cta")}</Typography>
        </Button>
      </Stack>
    </Paper>
  );
};
